import React, { Component } from "react";
import Header from './Header.js';
import '../css/projects.css';
import {
    Link
} from "react-router-dom";

class Projects extends Component {

    render() {
        return (
            <div className="App">
                <Header />
                <main className="App-main">
                    <div className="Content-main">
                    <h1>In House Projects</h1>
                    <p>
                        At I Think Music we are working on two in house software development projects, a D2C Ecommerce platform with the working title <b>Zig Zag Dot </b>
                         and an open source master distrubtion platform code name <b>The Music Meta Machine</b>. Both projects are in thier infantancy but share the goal
                        of offering afordable soltion for content monitisation for the independent music sector.
                    </p>

                        <img src="/zigzag-logo.svg" className="Project-logo" alt="logo"/>

                        <p><b>Zig Zag Dot // </b>Our Mission is to create a subscription streaming platform for the independent music industry that enables it to generate a direct relationship
                            with, and income from, their listeners. Our Vision is that Zig Zag Dot will become the preferred platform used for direct to customer subscription
                            streaming services within an independent music industry that generates an increased proportion of their income from direct interaction with listeners.
                            <br/>visit http://www.zigzagdot.com for more information
                        </p>


                        <img src="/Logo-MMM-2.svg" className="Project-logo" alt="logo"/>
                    <p>

                    </p>

</div>
                </main>
            </div>
        );
    }
};

export default Projects;
