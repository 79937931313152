import React, { Component } from "react";
import logo from '../itm-header-logo.svg';
import '../css/header.css';
import {
    Link
} from "react-router-dom";

class Header extends Component {

    render() {
        return (
            
                <header className="Page-header">
                    <Link to="/about"><div className="logo-link"><img src={logo} className="Page-logo" alt="logo"/></div></Link>
                    <div className="menu-items">
                        <a href="/menu">
                            <svg viewBox='-5 0 10 8' width='30'>
                                <line y2='6'
                                      stroke='#a39ea9'
                                      stroke-width='10'
                                      stroke-dasharray='1 1'/>
                            </svg>
                        </a>
                    </div>
                </header>

            
        );
    }
};

export default Header;