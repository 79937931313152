

const AboutStories =[
    
    {
        title: "Software",
        body_p:[
            "There can be a big learning curve for creatives and developers working on music software projects.",
            "Our direct experience and familiarity with music data models, metadata, distribution formats and music rights segmentation, reporting formats and data processing strategies can be key to project success.",
            " Talk to our team. about how we can help you build your products."],
        link: "/bespoke-development"
    },
    {
        title: "In House",
        body_p:[
            "When we are not building beautiful software for our clients we are working on our own ideas. We have two ongoing projects:",
            "< The Media Meta Machine > is an open source application labels and distributors can use to manage and send content to DSPs.",
            "< Zig Zag Dot > is a D2C subscription service for Independent record labels, venues, cooperatives and scenes."],
        link: "/In-House-projects"
    },
    {
        title: "Consultancy",
        body_p:[
            "If you do not require our software development services but feel you could value our advice and expertise we also offer a consultancy service.",
            "We can perform due diligence for merge and acquisitions, investment or strategy audits.",
            "We can advise on new technologies, product strategy and innovation, help with platform scaling or third party integration."
        ],
        link: "/advisory"
    }
    
] 

export default AboutStories;
