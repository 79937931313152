import React, { Component } from "react";
import Header from './Header.js';
import Footer from './Footer.js';
import Divider from '../divide_01.svg';
import thlogo from '../th-logo.png';
import phone from '../phone-icon.png';
import AboutCarousel from '../components/AboutCarousel.js';

import '../css/about.css';
import {
    Link
} from "react-router-dom";

class About extends Component {

    render() {
        return (
            <div className="App">
                <Header />
                <main className="App-main">
                    <div>
                        <div className="About-content-box">
                            <div className="About-left-box">
                                <h1>Music <div className="pink-txt">Software</div></h1>

                                <p>
                                    We design, develop and deploy<br />
                                    bespoke digital products and<br />
                                    technology exclusively for the<br />
                                    music industry.
                                </p>
                                <a href="/bespoke-development">find out more</a>
                            </div>

                            <AboutCarousel />


                        </div>
                    </div>
                    <div className="Contacts-section">
                        <img src={Divider} className="Divide-one" alt="divider"/>


                        <div className="th-logo-container">
                            <img src={thlogo} className="Thlogo" alt="Tree House"/>
                        </div>
                        <div className="address-container">
                            <b>I Think Music BV</b> <br />
                            NDSM Treehouse,<br />
                            T.T. Neveritaweg 55 C-03,<br />
                            1033 WB Amsterdam,<br />
                            The Netherlands.<br />
                        </div>
                        <div className="phone-icon-container">
                            <img src={phone} className="Thlogo" alt="Get in touch"/>
                        </div>
                        <div className="phone-container">
                            <b>Telephone: </b> +31 (0) 621 852 112<br />
                            <b>Email: </b> mc (at) ithinkmusic.com<br />
                            <b>Twitter: </b><a href="https://twitter.com/ithinkmusic" >twitter.com/ithinkmusic</a><br />
                            <b>Linkedin: </b><a href="https://www.linkedin.com/company/ithinkmusic" >linkedin.com/company/ithinkmusic</a><br />
                            <b>GitHub: </b><a href="https://github.com/ithinkmusic" >github.com/ithinkmusic</a><br />
                        </div>
                        <div className="biz-number-container">
                            &nbsp;<br />&nbsp;<br />&nbsp;<br />

                            <b>COC number:</b> 82721440 0000<br />
                            <b>VAT number:</b>	NL862579569B01
                        </div>
                    </div>

                </main>

            </div>
        );
    }
};

export default About;
