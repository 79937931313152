import React, { Component } from "react";
import Header from './Header.js';
import {
    Link
} from "react-router-dom";

class Contact extends Component {

    render() {
        return (
            <div className="App">
                <Header />
                <main className="App-main">
                    <h1>Contacts</h1>
                    <p>
                       Email: mc@ithinkmusic.com
                    </p>

                </main>
            </div>
        );
    }
};

export default Contact;
