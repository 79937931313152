import React, { Component } from "react";
import Header from './Header.js';
import '../css/consult.css';
import {
    Link
} from "react-router-dom";

class Consult extends Component {

    render() {
        return (
            <div className="App">
                <Header />
                <main className="App-page-main">
                    <div className="Content-main">
                        
                        <h1>&#60; Consultancy &#47;&#62;</h1>
                        <p>
                            If you do not require our software development services but feel you could value our advice and expertise we also
                            offer a consultancy service.

                            We can perform due diligence for merge and acquisitions, investment or strategy audits.

                            We can advise on new technologies, product strategy and innovation, help with platform scaling or third party integration.
                        </p>
                        
                        <div className="Artical">
                            <img src="/IMG_0060.jpeg" className="dd-image"/>
                                <h2>&#8600; M&A Strategy & Due Diligence</h2>
    
                                <p>
                                    Some companies treat M&A as a strategy. That’s not our view. Instead, we believe M&A is an
                                    important enabler of strategy and long-term value. Our consultancy work focuses on your
                                    technology and services and how they can be integrated with M&A Prospects.
                                </p>
                                <p>
                                    <ul>
                                        <li>
                                            <b>Buy-side Due Diligence: </b>We can help you evaluate the technology, development capabilities,
                                            product fit, road-map viability, scalability and technical debt associated with an acquisition
                                            prospect to giving you a clear and honest picture of for investment.
                                        </li><br/>
                                        <li>
                                            <b>Sell-side Due Diligence: </b>Our direct experience with M&A in the music industry will help you
                                            prepare for and audit, we have first hand experience of a sell-side due diligence processes used by
                                            key players in the music industry which will really help you prepare for a technology audit.
                                        </li><br/>
                                        <li>
                                            <b>Post M&A integration strategy: </b>Leveraging an acquisition and it technology effectively can be
                                            critical. May factors are at play and there plenty of thing to get right. We can help you develop and
                                            execute an integration strategy helping you get the most from your investment.
                                        </li>
                                    </ul>
                                </p>
                            <p>
                                Many things can compromise a deal that looks great on paper—a misaligned management team, cultural conflicts, a slow
                                integration process, disruptions that reach customers or businesses, a lack of value-creation rigor, or aspirations
                                that fail to capitalize on transformational opportunities. We help clients address the most critical success factors
                                from start to finish, and we mitigate risks inherent in every deal.
                            </p>
                        </div>
                        <div className="Artical">
                            <h2>New Technologies</h2>
    
                            <p>
                                We working an an ever changing industry where the introduction of new technologies and associated service services
                                as an out sized and lasting effect on businesses. Sometime it can be difficult to full access or understand the effects
                                new technology will have on our strategy, while taking a "wait an see" approach to development in the industry is often
                                the best strategy there may be time when you need expertise and a level head to help you quantify an opportunity early.
    
                                We have first hand experience with:
    
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        <b>Block-chain Technology:</b> The application of block-chain technology in the music industry has so far promised
                                        a lot and delivered very little in terms of tangible gains. The speculative boom and bust of cryto-currencies in part
                                        has driven an out sized interest in this very innovative category of technology. If you need help understanding
                                        how block-chain and or NFTs can be used now or in the future by you business were can be of help.
                                    </li><br/>
                                    <li>
                                        <b>AI & Automation: </b>The data we get back from DSPs is big, to big for us to consider and analuys manauly.
                                    </li><br/>
                                    <li>
                                        <b>Audio Fingerprinting: </b>Leveraging
                                    </li><br/>
                                    <li>
                                        <b>DDEX / Metadata: </b>Leveraging
                                    </li>
                                </ul>
                            </p>
                        </div>
                        <div className="Artical">
                            <h2>Product Strategy and Innovation</h2>
    
                            <p>
                                We believe the ideas your business needs to develop and evolve product strategy are already
                                there in the minds of the teams and individuals working in you company. We can help you
                                turn those ideas into something concrete, creating, documenting and visualising a solid
                                stratergy specific to you position an capabilities. We offer:
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        <b>Product Innovation Workshop & Off Sites: </b>
                                    </li><br/>
                                    <li>
                                        <b>Competitor analysis: </b>Our
                                    </li><br/>
                                    <li>
                                        <b>Roadmap Analysis & Consultation: </b>Leveraging
                                    </li>
                                </ul>
                            </p>
                            <p>
                                An external consultant helping your teams develop there ideas into a concrete strategy
                                can have a lasting effect on you business creating repatabel structure for product innovation.
                            </p>
                        </div>
                        <div className="Artical">
                            <h2>Platform Scaling</h2>
                            <p>
                                One of the key problems that accompanies the success of any new startup is scalability making
                                the right choices can save a significant promotion of you budget as your company grows. I Think Music
                                can work with you to develop the best strategy to scale you platform working with
                                your team to define budget, technology choice and approach.
                            </p>
    
                            <h2>Third Party Integration</h2>
                            <p>
                                Buy or build decistion are critical to a growing business, your product and development resources
                                should be focus on building the services that differentiate your business in the market. Integration
                                with third party SAAS solutions can save valuable inhouse resources for the projects that count.
                            </p>
                            <p>
                                We have a wealth of first hand experience with SAAS solution avaliable in the music industry and can
                                guide you though the process of choosing the right solution for you.
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        );
    }
};

export default Consult;
