import React, { Component, useState  } from "react";
import AboutStories from '../data/AboutStories.js';

import {
    Link
} from "react-router-dom";



class AboutCarousel extends Component {

    constructor(props) {
        super(props);
        // Don't call this.setState() here!
        this.state = { counter: 0 };
        this.story = AboutStories[2];

    }

    tick() {

        if (this.state.counter == AboutStories.length -1){this.state.counter = 0;}else{this.state.counter++};
        this.story = AboutStories[this.state.counter];
        this.forceUpdate();

    }

    componentDidMount() {

        this.timerID = setInterval(
            () => this.tick(),
            20000
        );
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }


    render() {

        return (
            <div className="About-right-box" >
                <h2>&#60; {this.story.title} &#62;</h2>

                {this.story.body_p.map((p, index) => (
                    <p key={index}>
                        {p}
                    </p>
                ))}


                <div className="more-info-container">
                    <a href={this.story.link}>
                        <svg class="more-info-icon" width="42px" height="42px" viewBox="0 0 42 42" version="1.1"><g transform="translate(1, 1)" stroke="#a39ea9" stroke-width="1" fill="none" fill-rule="evenodd"><circle class="icon-circle" fill-rule="nonzero" cx="20" cy="20" r="20"></circle><g transform="translate(14, 15)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><polyline class="icon-chevron" transform="translate(4.538535, 4.538535) rotate(-45) translate(-4.538535, -4.538535) " points="1.53853469 7.53853469 7.53853469 7.53853469 7.53853469 1.53853469"></polyline></g></g></svg>
                    </a>
                </div>

                <div className="box-footer"></div>
            </div>
        );
    }

    
};

export default AboutCarousel;
