import React, { Component } from "react";
import Header from './Header.js';
import '../css/develop.css';
import {
    Link
} from "react-router-dom";

class Develop extends Component {

    render() {
        return (
            <div className="App">
                <Header />
                <main className="App-main">
                    <div className="Content-main">
                        <h1>Bespoke Development</h1>
                        <p>
                            There can be a big learning curve for creatives and devlopers working on music software projects.

                        </p>
                        <p>

                            Our direct experience and familiarity with music data models, metatdata, distribution formats and music
                            rights segmatation, reporting formats and data processing stratergies can be key to project success.

                        </p>
                        <p>

                            Talk to our team about how we can help you build your products.
                        </p>
                    </div>
                </main>
            </div>
        );
    }
};

export default Develop;
