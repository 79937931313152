
import './App.css';
import Home from './pages/Home.js';
import Consult from './pages/Consult.js';
import Develop from './pages/Develop.js';
import Projects from './pages/Projects.js';
import Contact from './pages/Contact.js';
import About from './pages/About.js';
import React, { Component } from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";


function App() {

        return (
            <Router>
                <div>
                    <Switch>
                        <Route exact path="/">
                            <Home />
                        </Route>
                       
                        <Route path="/about"><About /></Route>
                        <Route path="/advisory"><Consult /></Route>
                        <Route path="/bespoke-development"><Develop /></Route>
                        <Route path="/in-house-projects"><Projects /></Route>
                        <Route path="/contacts"><Contact /></Route>
                    </Switch>
                </div>
            </Router>
        );

}

export default App;

