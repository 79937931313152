import React, { Component } from "react";
import logo from '../itm-logo-black.svg';
import {
    Link
} from "react-router-dom";

class Home extends Component {

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    <Link to="/"><img src={logo} className="App-logo" alt="logo"/></Link>
                </header>
            </div>
        );
    }
};

export default Home;
