import React, { Component } from "react";

import '../css/footer.css';
import {
    Link
} from "react-router-dom";

class Header extends Component {

    render() {
        return (
            
                <footer className="Page-footer">
                    <div className="">
                       footer
                    </div>
                </footer>

            
        );
    }
};

export default Header;